"use strict";

/**
 * Define the output of this file. The output of CSS and JS file will be auto detected.
 *
 * @output plugins/global/plugins.bundle
 */

//** Begin: Global mandatory plugins
window.jQuery = window.$ = require("jquery");
require("bootstrap");
require("morris.js/morris.js");
require("block-ui");
require("autosize");
require("clipboard");
window.moment = require("moment");
window.Sticky = require("sticky-js");
window.Chart = require("chart.js");
window.Raphael = require("raphael");
window.Cookies = require("js-cookie");
window.Popper = require("popper.js");
require("jquery-form");

// Toastr
window.toastr = require("toastr");

// Tooltips
import Tooltip from "tooltip.js";

window.Tooltip = Tooltip;

// Perfect-Scrollbar
window.PerfectScrollbar = require("perfect-scrollbar/dist/perfect-scrollbar");
//** End: Globally mandatory plugins


//** Begin: Global optional plugins
// Bootstrap-Select
require("bootstrap-select");

// Bootstrap-Datetimepicker
require("bootstrap-datetime-picker");

// Select2
require("select2");

// Autosize
window.autosize = require("autosize");

// Bootstrap-Maxlength
require("bootstrap-maxlength");

// Bootstrap-Touchspin
require("bootstrap-touchspin");

// Animate.css

// Cropper.js
window.Cropper = require("cropperjs");

// Font Icons
require("socicon");
//** End: Global optional plugins

// Datatables.net
require("datatables.net");
require("datatables.net-bs4");
require("metronic/_theme/js/global/integration/plugins/datatables.init.js");


// Dependencies
window.jQuery = window.$ = require("jquery");
require("bootstrap/js/dist/tooltip");

// jQueryUI
require("jquery-ui"); require("jquery-ui/ui/widgets/sortable");
require("jquery-ui/ui/disable-selection");


// Uppy
window.Uppy = require("uppy");
